
import { DiagramObjectShape, DiagramObjectType, DiagramType, findModelObjectsInScope, ModelObject, ModelObjectType } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import Tabs, { ITab } from '@/components/tabs.vue'
import { iconUrlForTheme } from '@/helpers/theme'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'

const EMPTY_IMAGE = document.createElement('img')
EMPTY_IMAGE.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

type Model = {
  deleteEnabled: boolean
  diagramCount: number
  existsInView: boolean
  model: ModelObject
  subtitles?: string[]
  title: string
}

type ModelGroup = {
  height: number
  models: Model[]
  subtitle?: string
  title: string
  type: ModelObject['type'] | 'other'
}

const MODEL_LEVEL_OBJECTS: Record<DiagramType, ModelObjectType[]> = {
  'app-diagram': ['system', 'actor', 'group', 'app', 'store'],
  'component-diagram': ['system', 'actor', 'group', 'app', 'store', 'component'],
  'context-diagram': ['system', 'actor', 'group']
}

const MODEL_GROUP_ORDER: ModelGroup['type'][] = [
  'group',
  'actor',
  'system',
  'app',
  'store',
  'component',
  'other'
]

@Component({
  components: {
    Menu,
    OrganizationUpgradeMenu,
    Tabs
  },
  name: 'ModelObjectsMenu'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  searchModel = ''
  selectedIndex = -1
  selectedIndexBeforeSearch = -1
  lastModelObjectsMenu: string | null = 'this'
  inCurrentObjectScope = false

  visible = false

  iconUrlForTheme = iconUrlForTheme

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion?.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get objectDeleteDialog () {
    return this.$queryValue('object_delete_dialog')
  }

  get modelObjectsMenu () {
    return this.$queryValue('model_objects_menu')
  }

  get drawerExpanded () {
    const drawer = this.$queryValue('drawer')
    const flow = this.$queryValue('flow')
    return !!flow && drawer === 'expanded'
  }

  get organizationUpgradeDialog () {
    return this.$queryValue('organization_upgrade_dialog')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentDiagramContent () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)!
  }

  get currentDiagramModel () {
    return this.modelModule.objects[this.currentDiagramContent.modelId]
  }

  get organizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get domains () {
    return Object.values(this.domainModule.domains)
  }

  get tabs (): ITab[] {
    return [
      {
        caption: this.filteredModelGroupsThisDomain.reduce((p, c) => p + c.models.length, 0),
        id: 'this',
        text: 'This domain',
        to: {
          params: this.$params,
          query: this.$setQuery({
            model_objects_menu: 'this'
          })
        }
      },
      {
        caption: this.filteredModelGroupsOtherDomain.reduce((p, c) => p + c.models.length, 0),
        id: 'other',
        text: 'Other domains',
        to: {
          params: this.$params,
          query: this.$setQuery({
            model_objects_menu: 'other'
          })
        }
      }
    ]
  }

  get defaultIndex () {
    switch (this.currentDiagramContent.type) {
      case 'context-diagram': return this.modelGroups.findIndex(o => o.type === 'system')
      case 'app-diagram': return this.modelGroups.findIndex(o => o.type === 'app')
      case 'component-diagram': return this.modelGroups.findIndex(o => o.type === 'component')
    }
  }

  get viewModelObjectIds () {
    return Object.values(this.currentDiagramContent.objects).map(o => o.modelId)
  }

  get diagramModelObjectFamilyIds () {
    return [this.currentDiagramContent.modelId, ...this.modelModule.objects[this.currentDiagramContent.modelId]?.parentIds ?? []]
  }

  get modelObjectIdsInScope () {
    const currentDiagramModel = this.currentDiagramModel
    return findModelObjectsInScope(this.modelModule.objects, this.currentDiagramContent.modelId).filter(o => o.domainId === currentDiagramModel.domainId).map(o => o.id)
  }

  get modelObjectIdsProposed () {
    return this.currentDiagramContent.tasksProposed.map(o => o.task.type === 'model-object-create' ? o.task.id : undefined).filter((o): o is string => !!o)
  }

  get modelGroupsThisDomain () {
    const modelGroups: ModelGroup[] = [{
      height: 48,
      models: [],
      title: 'Systems',
      type: 'system'
    }]
    if (this.currentDiagramContent.type === 'app-diagram' || this.currentDiagramContent.type === 'component-diagram') {
      modelGroups.push({
        height: 48,
        models: [],
        title: 'Apps',
        type: 'app'
      })
    }
    if (this.currentDiagramContent.type === 'component-diagram') {
      modelGroups.push({
        height: 48,
        models: [],
        title: 'Components',
        type: 'component'
      })
    }

    const modelObjectIdsProposed = this.modelObjectIdsProposed
    const currentDiagramModel = this.currentDiagramModel
    const modelObjectIdsInScope = this.modelObjectIdsInScope
    const viewModelObjectIds = this.viewModelObjectIds

    Object
      .values(this.modelModule.objects)
      .filter(o =>
        o.domainId === currentDiagramModel.domainId &&
        MODEL_LEVEL_OBJECTS[this.currentDiagramContent.type].includes(o.type) &&
        (!this.inCurrentObjectScope || modelObjectIdsInScope.includes(o.id))
      )
      .forEach(o => {
        const external = o.external || this.modelModule.objects[o.id]?.parentIds.some(e => this.modelModule.objects[e]?.external)
        const parent = o.parentId ? this.modelModule.objects[o.parentId] : null

        let models: Model[] | undefined = modelGroups.find(m => m.type === o.type)?.models
        if (!models) {
          models = []
          modelGroups.push({
            height: 48,
            models,
            title: `${o.type.slice(0, 1).toUpperCase()}${o.type.slice(1)}s`,
            type: o.type
          })
        }

        let subtitles: string[] | undefined

        if (modelObjectIdsInScope.includes(o.id)) {
          subtitles = external ? ['[External]'] : undefined
        } else if (parent) {
          subtitles = [`${external ? '[External] ' : ''}From: ${parent.name || `${parent.type.slice(0, 1).toUpperCase()}${parent.type.slice(1)}`}`]
        }

        models.push({
          deleteEnabled: this.currentDiagramContent.status === 'draft' ? modelObjectIdsProposed.includes(o.id) : true,
          diagramCount: Object.keys(o.diagrams).length,
          existsInView: viewModelObjectIds.includes(o.id),
          model: o,
          subtitles,
          title: o.name || `${o.type.slice(0, 1).toUpperCase()}${o.type.slice(1)}`
        })
      })

    return modelGroups
  }

  get filteredModelGroupsThisDomain () {
    return this.modelGroupsThisDomain.map(o => {
      if (this.searchModel) {
        const fuzzy = new Fuse(o.models, {
          keys: [
            'title',
            'subtitles'
          ],
          threshold: 0.3
        })
        return {
          ...o,
          models: fuzzy.search(this.searchModel).map(o => o.item)
        }
      } else {
        return o
      }
    })
  }

  get modelGroupsOtherDomain () {
    const modelGroups: ModelGroup[] = [{
      height: 48,
      models: [],
      title: 'Systems',
      type: 'system'
    }]
    if (this.currentDiagramContent.type === 'app-diagram' || this.currentDiagramContent.type === 'component-diagram') {
      modelGroups.push({
        height: 64,
        models: [],
        title: 'Apps',
        type: 'app'
      })
    }
    if (this.currentDiagramContent.type === 'component-diagram') {
      modelGroups.push({
        height: 64,
        models: [],
        title: 'Components',
        type: 'component'
      })
    }

    const modelObjectIdsProposed = this.modelObjectIdsProposed
    const viewModelObjectIds = this.viewModelObjectIds
    const currentDiagramContent = this.currentDiagramContent

    Object
      .values(this.modelModule.objects)
      .filter(o =>
        o.domainId !== this.currentDiagramModel.domainId &&
        MODEL_LEVEL_OBJECTS[currentDiagramContent.type].includes(o.type) &&
        !this.inCurrentObjectScope
      )
      .forEach(o => {
        const parent = o.parentId ? this.modelModule.objects[o.parentId] : null
        if (parent) {
          let models: Model[] | undefined = modelGroups.find(m => m.type === o.type)?.models
          if (!models) {
            models = []
            modelGroups.push({
              height: parent.type === 'root' ? 48 : 64,
              models,
              title: `${o.type.slice(0, 1).toUpperCase()}${o.type.slice(1)}s`,
              type: o.type
            })
          }

          const external = o.external || this.modelModule.objects[o.id]?.parentIds.map(e => this.modelModule.objects[e].external)

          let subtitles: string[]

          if (parent.type === 'root') {
            subtitles = [`${external ? '[External] ' : ''}in: ${this.domainModule.domains[parent.domainId].name}`]
          } else {
            subtitles = [
              `${external ? '[External] ' : ''}in: ${this.domainModule.domains[parent.domainId].name}`,
              `From: ${parent.name || `${parent.type.slice(0, 1).toUpperCase()}${parent.type.slice(1)}`}`
            ]
          }

          models.push({
            deleteEnabled: currentDiagramContent.status === 'draft' ? modelObjectIdsProposed.includes(o.id) : true,
            diagramCount: Object.keys(o.diagrams).length,
            existsInView: viewModelObjectIds.includes(o.id),
            model: o,
            subtitles,
            title: o.name || `${o.type.slice(0, 1).toUpperCase()}${o.type.slice(1)}`
          })
        }
      })

    return modelGroups
  }

  get filteredModelGroupsOtherDomain () {
    return this.modelGroupsOtherDomain.map(o => {
      if (this.searchModel) {
        const fuzzy = new Fuse(o.models, {
          keys: [
            'title',
            'subtitles'
          ],
          threshold: 0.3
        })
        return {
          ...o,
          models: fuzzy.search(this.searchModel).map(o => o.item)
        }
      } else {
        return o
      }
    })
  }

  get modelGroups () {
    const modelGroups = this.visible ? this.lastModelObjectsMenu === 'this' ? this.filteredModelGroupsThisDomain : this.filteredModelGroupsOtherDomain : []

    modelGroups.forEach(o => o.models.sort((a, b) => a.model.name.localeCompare(b.model.name)))
    modelGroups.sort((a, b) => MODEL_GROUP_ORDER.indexOf(a.type) > MODEL_GROUP_ORDER.indexOf(b.type) ? 1 : -1)

    return modelGroups
  }

  get modelGroupKey () {
    return this.modelGroups.map(o => o.type).join('-')
  }

  get modelCount () {
    return {
      count: this.modelGroupsThisDomain.filter(o => o.type !== 'other').reduce((p, c) => p + c.models.length, 0),
      diagramHandleId: this.currentDiagramHandleId
    }
  }

  @Watch('defaultIndex')
  onDefaultIndexChanged (defaultIndex: number) {
    if (this.modelGroups[defaultIndex].models.length === 0) {
      if (this.selectedIndexBeforeSearch === -1) {
        this.selectedIndexBeforeSearch = this.selectedIndex
      }

      const indexWithLength = this.modelGroups.findIndex(o => o.models.length)
      if (indexWithLength > -1) {
        this.selectedIndex = indexWithLength
      }
    } else {
      this.selectedIndex = defaultIndex >= 0 ? defaultIndex : 0
    }
  }

  @Watch('searchModel')
  onSearchModelChanged (searchModel: string, prevSearchModel: string) {
    if (searchModel && searchModel !== prevSearchModel && this.modelGroups[this.selectedIndex].models.length === 0) {
      if (this.selectedIndexBeforeSearch === -1) {
        this.selectedIndexBeforeSearch = this.selectedIndex
      }

      const indexWithLength = this.modelGroups.findIndex(o => o.models.length)
      if (indexWithLength > -1) {
        this.selectedIndex = indexWithLength
      }
    }

    if (!searchModel && prevSearchModel && this.selectedIndexBeforeSearch > -1) {
      this.selectedIndex = this.selectedIndexBeforeSearch
      this.selectedIndexBeforeSearch = -1
    }
  }

  @Watch('modelCount')
  onModelCountChanged (modelCount: this['modelCount'], prevModelCount: this['modelCount']) {
    if (modelCount.count !== prevModelCount.count) {
      this.$emit('model-count', modelCount.count)

      if (modelCount.diagramHandleId === prevModelCount.diagramHandleId) {
        this.$emit('model-count-changed', modelCount.count > prevModelCount.count ? 'increase' : 'decrease')
      }
    }
  }

  @Watch('modelObjectsMenu')
  onModelObjectsMenuChanged (modelObjectsMenu: string | null) {
    if (modelObjectsMenu) {
      this.lastModelObjectsMenu = modelObjectsMenu
    }
  }

  mounted () {
    this.selectedIndex = this.defaultIndex >= 0 ? this.defaultIndex : 0

    this.$emit('model-count', this.modelCount.count)
  }

  opened () {
    this.trackEvent(this.selectedIndex)
  }

  trackEvent (index: number) {
    const type = this.modelGroups[index]?.type
    if (type) {
      analytics.modelObjectsMenu.track(this, {
        diagramType: this.currentDiagramContent.type,
        landscapeId: [this.currentLandscape.id],
        modelObjectsMenuType: type,
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  modelCreate (type: DiagramObjectType, modelId: string) {
    const shape: DiagramObjectShape = this.diagramModelObjectFamilyIds.includes(modelId) || type === 'group' ? 'area' : 'box'
    this.$emit('model-create', type, shape, modelId)
  }

  modelDrag (type: DiagramObjectType, modelId: string, e: DragEvent & { target?: HTMLElement }) {
    e.target?.blur()
    e.dataTransfer?.setDragImage(EMPTY_IMAGE, 0, 0)

    const shape: DiagramObjectShape = this.diagramModelObjectFamilyIds.includes(modelId) || type === 'group' ? 'area' : 'box'
    this.$emit('model-drag', type, shape, modelId)

    e.preventDefault()
  }

  selectedIndexChanged (selectedIndex: number) {
    this.selectedIndexBeforeSearch = -1
    this.selectedIndex = selectedIndex
  }
}
